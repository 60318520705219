import consts from "consts/consts";
import { Component } from "react";

class LinkDevice extends Component {
  componentDidMount() {
    const queryString = new URLSearchParams(window.location.search);
    const code = queryString.get("code") || "";
    let search = "?pairing=true";
    if (code) search = `${search}&code=${code}`;
    this.props.history.push({
      pathname: consts?.routes?.devices?.url || "/devices",
      search,
    });
  }
  render() {
    return null;
  }
}

export default LinkDevice;
