import consts from "consts/consts";

import { generateImageUrl } from "../helpers/imageHelper.js";
import { formatFullDateAndTime, formatSecondsInHumanDuration } from "../helpers/timeHelper.js";
import Record from "./Record.js";

export default class EPG {
  constructor(item, records = []) {
    // console.log("EPG input", item);
    let metadata = {};
    try {
      if (item.direct_metadata) {
        metadata = JSON.parse(item.direct_metadata);
      }
      if (item.directMetadata) {
        metadata = JSON.parse(item.directMetadata);
      }
      if (item.metadata) {
        metadata = item.metadata;
      }
    } catch (exception) {
      console.error(exception);
    }
    this.metadata = metadata;
    this.raw = item;
    this.idEpg = item.id_epg || item.idAsset || item.idEpg;
    this.idEvent = item.idEvent;
    this.type = item.idType || 500;
    this.title = item.title;
    if (item.idChannel && item.idChannel.length > 0) {
      this.channelId = item.idChannel[0]; // (For epgAsset)
    } else if (item.id_channel) {
      this.channelId = item.id_channel; // (For player remote)
    } else if (item.channelIds && item.channelIds.length > 0) {
      this.channelId = item.channelIds[0]; // (For search results)
    }
    this.startTime = item.startutc || item.startUtc; // in s
    this.endTime = item.endutc || item.endUtc; // in s
    this.duration = item.duration; // in ms
    this.genre = item.genre;
    this.id = metadata.AD_IMAGEID;
    this.synopsis = item.synopsis;
    this.rating = item.rating;
    this.catchupAvailable = metadata.SA_CATCHUP;
    this.originalCategory = metadata.AD_ORICATEG;
    this.releaseYear = metadata.TS_RELEDATE;
    this.cast = metadata.AD_LIACTORS;
    this.directors = metadata.AD_LIREALIS;
    this.moralityLevel = metadata.AD_CSASNAME;
    this.autoClassification = metadata?.["tv.alphanetworks.tucano.autoClassification"];

    this.subscribed = item.isFta;
    this.fta = item.isSubscribed;
    this.startoverEnabled = item.startoverEnabled;
    this.displayImage = true;
    this.npvrEnabled = item.npvrEnabled;

    if (records && records.length > 0) {
      const recordItem = records.find(
        (recordItem) => recordItem.idEvent == this.idEpg || recordItem.idEvent == this.idEvent
      );
      if (recordItem) {
        this.record = new Record(recordItem);
        this.rawRecord = recordItem;
      }
    }
  }

  getRecord() {
    return this.record;
  }

  getRecordStatus() {
    return this.getRecord() ? this.getRecord().getStatus() : "Record";
  }

  getRawData() {
    return this.raw;
  }

  getIdEvent() {
    return this.idEvent;
  }

  getEpgId() {
    return this.idEpg;
  }

  getId() {
    return this.id;
  }

  getType() {
    return this.type;
  }

  getImage(domain = "/", options) {
    return `${domain}${generateImageUrl(this, options)}`;
  }

  getPosterImage(domain = "/", options) {
    return `${domain}${generateImageUrl(this, options)}`;
  }

  getTitle() {
    return this.title;
  }

  getStartTime() {
    const startTime = new Date(this.startTime * 1000);
    return startTime;
  }

  getBroadcastTime() {
    const startTime = new Date(this.startTime * 1000);
    return formatFullDateAndTime(startTime);
  }

  getEndTime() {
    const endTime = new Date(this.endTime * 1000);
    return endTime;
  }

  getDuration() {
    return this.duration;
  }

  getHumanDuration() {
    return formatSecondsInHumanDuration(this.duration / 1000);
  }

  shouldDisplayImage(time = 1) {
    const isProgramLive = this.isLive();
    let isProgramLong;
    if (this.id !== undefined && !isProgramLive) {
      isProgramLong = this.duration / 1000 / 60 / 60 >= time;
    }
    return isProgramLive || isProgramLong;
  }

  setDisplayImage(value) {
    this.displayImage = value;
  }

  getDisplayImage() {
    return this.displayImage;
  }

  getGenre() {
    return this.genre;
  }

  isLive() {
    const now = new Date().getTime();
    return this.getStartTime().getTime() < now && now < this.getEndTime().getTime();
  }

  getSynopsis() {
    return this.synopsis;
  }

  getProgress() {
    const now = new Date().getTime();
    const progress = ((now - this.getStartTime().getTime()) / this.getDuration()) * 100;
    if (progress >= 0 && progress <= 100) {
      return progress;
    } else {
      return undefined;
    }
  }

  getChannelId() {
    return this.channelId;
  }

  isCatchupAvailable() {
    return this.catchupAvailable;
  }

  getOriginalCategory() {
    return this.originalCategory;
  }

  getReleaseYear() {
    return this.releaseYear;
  }

  getRating() {
    return this.rating;
  }

  getCast() {
    if (this.cast) {
      return this.cast.join(", ");
    }
  }

  getCastFirstRoles() {
    if (this.cast) {
      return this.cast.slice(0, consts.mainCastMax);
    }
  }

  getCastSecondRoles() {
    if (this.cast) {
      return this.cast.slice(consts.mainCastMax, consts.mainCastMax + consts.subCastMax).join(", ");
    }
  }

  getDirectors() {
    if (this.directors) {
      return this.directors.join(", ");
    }
  }

  getMoralityLevel() {
    return this.moralityLevel;
  }

  isSubscribed() {
    return this.subscribed;
  }

  isFta() {
    return this.fta;
  }

  setSubscribed(value) {
    this.subscribed = value;
  }

  setFta(value) {
    this.fta = value;
  }

  get getAutoClassification() {
    return this.autoClassification;
  }

  get userHasAccess() {
    if (this.fta === true) return true;
    if (this.subscribed !== undefined && this.subscribed !== null && this.subscribed !== 0) return true;

    return false;
  }
}
