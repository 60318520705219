import Button, { ButtonType } from "components/Button";
import CheckIcon from "components/Icons/Check";
import Input, { InputType } from "components/Input";
import React, { Component } from "react";
import { Trans, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { TucanoActions } from "web-api/main";

import style from "./style.module.css";

class FormAskResetPassword extends Component {
  state = {
    email: "",
    error: false,
    resendSuccess: false,
  };

  constructor(props) {
    super(props);
    this.handleKeyPressed = this.handleKeyPressed.bind(this);
  }

  componentDidMount() {
    if (this.props.email !== undefined) {
      this.setState({ email: this.props.email });
    }
    document.addEventListener("keydown", this.handleKeyPressed, false);
  }

  handleKeyPressed(event) {
    if (event.key === "Enter") {
      this.handleSubmit();
    }
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyPressed, false);
    this.props.dispatch(TucanoActions.resetAskResetPassword());
  }

  componentDidUpdate(prevProps) {
    if (this.props.meta && this.props.meta !== prevProps.meta) {
      if (this.props.meta.error && this.props.meta.error.code === -1)
        this.setState({ error: true });
      else this.setState({ error: false });
    }
  }

  handleClose() {
    if (this.props.onCloseClick) {
      this.props.onCloseClick();
    }
  }

  handleEmailChange(key, value) {
    const updatedState = this.state;
    updatedState[key] = value;
    if (value !== "") {
      updatedState["error"] = false;
    } else {
      updatedState["error"] = true;
    }
    this.setState(updatedState);
  }

  handleSubmit(value) {
    if (!this.state.error && this.state.email !== "") {
      this.props
        .dispatch(TucanoActions.askResetPassword(this.state.email))
        .then((_res) => {
          if (value === "resend") {
            this.setState(
              {
                resendSuccess: true,
              },
              () => {
                let timeOut = setTimeout(() => {
                  this.setState({
                    resendSuccess: false,
                  });
                  clearTimeout(timeOut);
                }, 5000);
              }
            );
          }
        });
    }
  }

  render() {
    const { email } = this.state;
    const {
      isLoading,
      meta,
      t,
      statusResetPassword,
      redirectLinkResetPassword,
    } = this.props;
    const buttonType = isLoading
      ? ButtonType.LOADING
      : email === ""
      ? ButtonType.DISABLED
      : ButtonType.NORMAL;

    return (
      <div className={style.root}>
        {(meta === undefined || (meta.error && meta.error.code === -1)) && (
          <div>
            <div className={style.form}>
              <div className={style.container}>
                <p className={style.text}>
                  <Trans>reset-password-popup-message</Trans>
                </p>
                {statusResetPassword === "internal" && (
                  <Input
                    type={InputType.TEXT}
                    placeholder={t("Your email address")}
                    onChange={(value) => this.handleEmailChange("email", value)}
                    value={email}
                  />
                )}
                {this.state.error && (
                  <p className={style.error}>
                    <Trans>There is no account with this email</Trans>.
                  </p>
                )}
              </div>
            </div>
            <div>
              <Button
                rootClassName={
                  statusResetPassword === "internal"
                    ? style.submitButton
                    : style.LINK
                }
                type={buttonType}
                onClick={
                  statusResetPassword === "internal" &&
                  this.handleSubmit.bind(this)
                }
                href={
                  statusResetPassword === "external-popup" &&
                  redirectLinkResetPassword
                }
                target={
                  statusResetPassword === "external-popup" &&
                  redirectLinkResetPassword
                    ? "_blank"
                    : undefined
                }
              >
                <Trans>reset-password-popup-redirection-button</Trans>
              </Button>
            </div>
          </div>
        )}
        {meta !== undefined && !this.props.meta.error && (
          <div>
            <div className={style.form}>
              <div className={style.container}>
                <CheckIcon className={style.checkIcon} />
                <p className={style.email}>{this.state.email}</p>
                <p className={style.text2}>
                  <Trans>
                    You will receive an email with instructions to reset your
                    password shortly
                  </Trans>
                </p>
              </div>
            </div>
            <div>
              <Button
                rootClassName={style.submitButton}
                type={buttonType}
                onClick={this.handleClose.bind(this)}
              >
                <Trans>Ok</Trans>
              </Button>
            </div>
            <div>
              <Button
                rootClassName={
                  this.state.resendSuccess
                    ? style.resendButtonGrey
                    : style.resendButton
                }
                type={ButtonType.LINK}
                onClick={
                  this.state.resendSuccess
                    ? () => {}
                    : this.handleSubmit.bind(this, "resend")
                }
              >
                <Trans>Re-send the mail</Trans>
              </Button>
              {this.state.resendSuccess && (
                <p className={style.text2}>
                  <Trans>
                    The e-mail successfully resent, you can try again after a
                    few seconds
                  </Trans>
                </p>
              )}
              <p className={style.textGrey}>
                <Trans>Don't forget to check your spam</Trans>
              </p>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default compose(
  connect((state) => {
    return {
      isLoading: state.account.askResetPassword.loading,
      meta: state.account.askResetPassword.meta,
    };
  }),
  withTranslation()
)(FormAskResetPassword);
