import Button, { ButtonType } from "components/Button";
import Modal from "components/Modal";
import consts from "consts/consts";
import { isUrl } from "helpers/url";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import style from "./style.module.css";

class LoginSso extends Component {
  state = {
    showMore: false,
  };

  openModal = (showMore) => {
    this.setState({ showMore });
  };

  render() {
    const { openPopupAuthSSO, t, Translate } = this.props;
    const { showMore } = this.state;
    let { maxOperatosToShow } = consts;
    maxOperatosToShow =
      maxOperatosToShow > 6 ? 6 : maxOperatosToShow < 1 ? 1 : maxOperatosToShow;
    const toShowMore =
      consts.SSOConfig && consts.SSOConfig.length > maxOperatosToShow;
    return (
      <div className={style.loginSSO}>
        <p>
          <Translate t={t}>use your operator credentials</Translate>
        </p>
        {consts.loginWithSsosAsSingleButton ? (
          <div>
            <Button
              rootClassName={style.moreBtn}
              type={ButtonType.NORMAL}
              onClick={() => this.openModal(true)}
            >
              <Translate t={t}>Login with your operator</Translate>
            </Button>
          </div>
        ) : (
          <>
            <div className={style.operatos}>
              {consts.SSOConfig &&
                consts.SSOConfig.slice(0, maxOperatosToShow).map((one) => (
                  <div
                    key={one.name}
                    className={style.ssoLogo}
                    onClick={() =>
                      openPopupAuthSSO(
                        one.oauthUri,
                        one.clientID,
                        one.scope,
                        consts.SSORedirectURI,
                        one.responseType,
                        one.name,
                        one.extraParams
                      )
                    }
                  >
                    <img
                      src={
                        isUrl(one.logoName)
                          ? one.logoName
                          : `/assets/${one.logoName}`
                      }
                      alt="sso-logo"
                    />
                    <p>{one.name}</p>
                  </div>
                ))}
            </div>
            {toShowMore && (
              <div className={style.moreSection}>
                <Button
                  rootClassName={style.moreBtn}
                  type={ButtonType.LOGINBORDERED}
                  onClick={() => this.openModal(true)}
                >
                  <Translate t={t}>More login options</Translate>
                </Button>
              </div>
            )}
          </>
        )}

        {showMore && (
          <Modal
            size={"md"}
            leftContent={{ title: t("Log in with your operator") }}
            rightContent={{ canDismiss: this.openModal.bind(this, false) }}
            overlay={true}
          >
            <div>
              {consts.SSOConfig &&
                consts.SSOConfig.map((one) => (
                  <div
                    key={one.name}
                    className={style.ssoListLogo}
                    onClick={() =>
                      openPopupAuthSSO(
                        one.oauthUri,
                        one.clientID,
                        one.scope,
                        consts.SSORedirectURI,
                        one.responseType,
                        one.name,
                        one.extraParams
                      )
                    }
                  >
                    <img
                      src={`${
                        isUrl(one.logoName)
                          ? one.logoName
                          : `assets/${one.logoName}`
                      }`}
                      alt="sso-logo"
                    />
                    <p>{one.name}</p>
                  </div>
                ))}
            </div>
          </Modal>
        )}
      </div>
    );
  }
}

export default withTranslation()(LoginSso);
