import {
  resetChannelFilter,
  setChannelFilter,
  setChannelSort,
  setDirectArrayForFavorites,
  setLoginShow,
  setModalNPVRInfo,
  setSuspensionShow,
  setTerm,
} from "actions/ui";
import classnames from "classnames";
import ContentWrapper from "components/ContentWrapper";
import Dropdown from "components/Dropdown";
import Grid from "components/Grid";
import Header from "components/Header";
import ArrowHeading from "components/Icons/Arrowheading";
import FavoriteAdd from "components/Icons/FavoriteAdd";
import FavoriteRemove from "components/Icons/FavoriteRemove";
import Filter from "components/Icons/Filter";
import Live from "components/Icons/Live";
import Sad from "components/Icons/Sad";
import SearchIcon from "components/Icons/Search";
import Loader from "components/Loader";
import ModalNPVRRecord from "components/ModalNPVRRecord";
import ThumbnailLive from "components/ThumbnailLive";
import ThumbnailSquare from "components/ThumbnailSquare";
import consts from "consts/consts";
import responsiveConf, { ResponsiveType } from "consts/responsive";
import PageAbstract from "containers/PageAbstract";
import { getFromLocal } from "helpers/localStorage";
import { renameObjectKey } from "helpers/utility";
import jwt_decode from "jwt-decode";
import React, { Fragment } from "react";
import { Trans, withTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroller";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { TucanoActions, TucanoSelectors } from "web-api/main";

import style from "./style.module.css";

const sortArray = [
  "N° ascending",
  "N° descending",
  "Name ascending",
  "Name descending",
  "Favorites first",
];
class PageLive extends PageAbstract {
  menuContextRef = React.createRef();
  menuContextFilterRef = React.createRef();
  menuContextSearchRef = React.createRef();
  findChannelRef = React.createRef();
  constructor(args) {
    super(args);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.state = {
      clientX: 0,
      oldClientX: 0,
      clientY: 0,
      oldClientY: 0,
      menuContextOpen: false,
      menuContext: {},
      resizing: false,
      isSortOpen: false,
      menuContextFilterOpen: false,
      menuContextFilter: {},
      menuContextSearchOpen: false,
      menuContextSearch: {},
      favoriteGroup: [],
      sortedGroups: [],
      token: null,
      filterInputFocus: false,
      inputFocus: false,
      inputValue: "",
      hasMore: true,
      currentPage: 0,
      channelsPerPage: 12,
      componentReady: false,
      items: [],
      paginating: false,
    };
  }
  static fetch(dispatch, isConnected, _params) {
    const languageId = getFromLocal("languageId") ?? consts.languageId;
    dispatch(TucanoActions.getChannels(undefined, undefined, languageId)).then(
      (_result) => {
        dispatch(TucanoActions.getEPG(new Date(), languageId, true));
        if (
          consts?.["features-availability"]?.["is-npvr-enabled"] &&
          isConnected
        ) {
          dispatch(TucanoActions.getNPVRRecords());
        }
      }
    );
  }
  openMenuContext(channel, clientX, clientY) {
    this.setState({
      menuContextOpen: !this.state.menuContextOpen,
      menuContext: { channel: channel, clientX: clientX, clientY: clientY },
    });
  }
  handleClickOnClose() {
    if (this.props.viewport.type !== "DESKTOP" && this.props.onClick) {
      // if ( this.props.onClick) {
      this.props.onClick();
    }
    this.setState({
      inputValue: "",
      inputFocus: false,
    });
    this.props.dispatch(setTerm(""));
  }

  handleClickOutside(event) {
    event.stopPropagation();
    // console.log(this.menuContextSearchRef.current);
    if (
      this.menuContextSearchRef &&
      !this.menuContextSearchRef.current?.contains(event.target) &&
      this.findChannelRef &&
      !this.findChannelRef.current?.contains(event.target)
    ) {
      this.setState({ menuContextSearchOpen: false });
    }
  }

  handleInputOnChange(event) {
    const term = event.target.value;
    this.setState({ inputValue: term });
    this.props.dispatch(setTerm(term));
  }
  isChannelFavorite = (id) => {
    const { channelFavorits } = this.props;
    const found =
      channelFavorits &&
      channelFavorits.find((channel) => channel.idChannel === id);
    return found !== undefined;
  };
  AddToFavorite = async (id) => {
    const { dispatch, isConnected } = this.props;
    let channelFavorits = this.props.channelFavorits;
    const isChannelFavorite = this.isChannelFavorite(id);
    if (isConnected) {
      if (isChannelFavorite) {
        const newChannelList = channelFavorits.filter(
          (channel) => channel.idChannel !== id
        );
        await dispatch(TucanoActions.addFavoriteChannel(newChannelList));
      } else {
        let order;
        if (channelFavorits.length > 0) {
          order = channelFavorits[channelFavorits.length - 1].order + 1;
        } else {
          order = 1;
        }
        channelFavorits.push({ idChannel: id, order: order });
        await dispatch(TucanoActions.addFavoriteChannel(channelFavorits));
      }
    } else {
      this.props.dispatch(setLoginShow(true));
      // this.props.history.push("?login=1");
    }
  };

  async componentDidMount() {
    const { t, groups, isConnected, profileToken, dispatch } = this.props;
    dispatch(resetChannelFilter());
    dispatch(setTerm(""));
    if (consts.appModules.live !== true) {
      this.props.history.push("/");
    }
    this.props.dispatch(
      setModalNPVRInfo({
        showModalNPVR: false,
        idRecord: "",
        npvrChannel: 0,
        typeRecordButton: "",
        isQuotaExceeded: false,
      })
    );
    document.title = `${t(this.props.route.title)} - ${consts.name}`;
    if (consts.appTitle) {
      document.title = `${consts.appTitle} - ${t(this.props.route.title)}`;
    }
    if (consts.liveMetaData.length > 0) {
      document.getElementsByTagName("meta")[3].content =
        consts.liveMetaData[0].content;
      document.getElementsByTagName("meta")[4].content =
        consts.liveMetaData[1].content;
      document.getElementsByTagName("meta")[5].content =
        consts.liveMetaData[2].content;
    }
    if (isConnected) {
      const decoded = jwt_decode(profileToken);
      this.setState({ token: decoded.id_profile });
    }
    this.constructor.fetch(this.props.dispatch, this.props.isConnected);
    await this.initialiseChannels(groups);
    document.addEventListener("mousedown", this.handleClickOutside);
  }
  initialiseChannels = async (groups) => {
    const { isConnected, channelFavorits, channels } = this.props;
    //kill me please: find a user's favorite channels and build a group that we add to sorting groups
    let favoriteGroup = [];
    channelFavorits?.map((favChan) => {
      channels?.forEach((channel) => {
        if (channel.id == favChan.idChannel) favoriteGroup.push(channel);
      });
    });
    if (
      this.props.filter !== "Favorites" ||
      !isConnected ||
      this.props.filter == null
    ) {
      await this.props.dispatch(setDirectArrayForFavorites(null));
    }
    // add favorite category to premade groups then resort
    let sortedGroups = groups;
    if (isConnected) {
      if (this.props.filter == "Favorites") {
        await this.props.dispatch(setDirectArrayForFavorites(favoriteGroup));
      }
    }
    sortedGroups = sortedGroups?.sort(function (a, b) {
      var textA = a.group.toUpperCase();
      var textB = b.group.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
    await this.setState({
      favoriteGroup: favoriteGroup,
      sortedGroups: sortedGroups,
    });
  };
  handleApplyFilter = async (filter) => {
    const { groups, isConnected } = this.props;
    // if (filter == "Favorites") {
    //   this.props.dispatch(setDirectArrayForFavorites(this.state.favoriteGroup));
    // } else {
    await this.props.dispatch(setTerm(""));
    if (filter.label !== "All channels") {
      this.props.dispatch(
        setChannelFilter(
          { value: filter.label, profile: this.state.token },
          isConnected
        )
      );
    } else {
      await this.props.dispatch(
        setChannelFilter(
          { value: null, profile: this.state.token },
          isConnected
        )
      );
    }
    await this.props.dispatch(setDirectArrayForFavorites(null));
    // }
    await this.initialiseChannels(groups);

    this.loadMore(true);
  };
  handleApplySort = async (item) => {
    const { isConnected } = this.props;
    await this.props.dispatch(
      setChannelSort({ value: item, profile: this.state.token }, isConnected)
    );

    this.loadMore(true);
  };
  componentWillUnmount() {
    const { isConnected } = this.props;
    if (!isConnected) {
      this.props.dispatch(
        setChannelSort({ value: null, profile: null }, isConnected)
      );
      this.props.dispatch(
        setChannelFilter({ value: null, profile: null }, isConnected)
      );
    }
    document.removeEventListener("mousedown", this.handleClickOutside);
  }
  subscriptionModal(asset) {
    if (this.props.subscriptionModal) {
      this.props.subscriptionModal(asset);
    }
  }
  toggleClickAccordion(_event) {
    const { menuContextSearchOpen } = this.state;
    const position = this.findChannelRef.current.getBoundingClientRect();
    this.setState({
      menuContextSearchOpen: !menuContextSearchOpen,
      menuContextSearch: { clientX: position.top, clientY: position.left },
    });
  }
  toggleClickSort() {
    const { isSortOpen } = this.state;
    this.setState({ isSortOpen: !isSortOpen });
  }
  gotoPlayerLive = async (channelId) => {
    const { accountStatus, dispatch } = this.props;
    if (accountStatus?.toLowerCase() === "suspended") {
      dispatch(setSuspensionShow());
    } else {
      this.props.history.push(
        consts.routes.playerlive.url.replace(":channelId", channelId)
      );
    }
    // consts.routes.playerlive.url.replace(
    //             ":channelId",
    //             epg.getChannelId()
    //           )
    // if (channelRef && channelRef[channelName]) {
    //   await this.setState({ menuContextSearchOpen: false });
    //   channelRef[channelName].scrollIntoView();
    // }
  };

  componentDidUpdate(prevProps, _prevState) {
    const { filteredChannels } = this.props;
    // if (
    //   this.state.items.length === 0 &&
    //   filteredChannels &&
    //   filteredChannels.sortedChannels &&
    //   filteredChannels.sortedChannels.length > 0
    // ) {
    //   this.loadMore();
    // }
    if (
      prevProps.ppvOptions.length !== this.props.ppvOptions.length ||
      (filteredChannels &&
        filteredChannels.sortedChannels &&
        JSON.stringify(filteredChannels.sortedChannels) !==
          JSON.stringify(prevProps?.filteredChannels?.sortedChannels))
    ) {
      this.loadMore(true);
    }
  }

  loadMore = (initialisePagination = false) => {
    const { currentPage, channelsPerPage, paginating } = this.state;
    const { filteredChannels } = this.props;
    if ((!this.state.hasMore && !initialisePagination) || paginating) return;
    this.setState({
      paginating: true,
    });
    const page = initialisePagination
      ? currentPage === 0
        ? currentPage
        : currentPage - 1
      : currentPage;
    const totalPages = Math.ceil(
      filteredChannels?.sortedChannels?.length / channelsPerPage
    );
    const paginatedItems =
      filteredChannels?.sortedChannels?.slice(
        0,
        channelsPerPage * (page + 1)
      ) || [];
    this.setState({
      items: paginatedItems,
      currentPage: page + 1,
      hasMore: totalPages > page + 1 ? true : false,
      paginating: false,
    });
  };

  render() {
    const {
      liveEPG,
      isLoading,
      isConnected,
      viewport,
      isMenuOpen,
      isEPGLoading,
      filteredChannels,
      filter,
      t,
      term,
      history,
      modalNPVRInfo,
    } = this.props;
    const {
      menuContextOpen,
      menuContext,
      menuContextSearchOpen,
      inputFocus,
      inputValue,
      sortedGroups,
      hasMore,
      items,
    } = this.state;
    const thumbnailSize = responsiveConf.find((item) => {
      return item.name === viewport.type;
    });
    /*
  DESKTOP WIDTH CALCULATE
    Open menu width = 300
    Closed menu width = 100
    Container padding = 100 (padding-right=50 padding-left=50)
  */
    let width = viewport.width - (isMenuOpen ? 300 : 100) - 100;
    /*
  PHONE WIDTH CALCULATE
    Menu width = 0
    Container padding = 20 (padding-right=10 padding-left=10)
  */
    width =
      viewport.type !== ResponsiveType.DESKTOP ? viewport.width - 5 : width;

    // WIDTH COLUMN MAX = 365
    let widthColumn = width - 30 > 365 ? 255 : width - 30;

    let sortedGroupsFormated;
    if (sortedGroups?.length > 0) {
      sortedGroupsFormated = sortedGroups.slice();
      sortedGroupsFormated.map((obj) => {
        renameObjectKey(obj, "count", "value");
        renameObjectKey(obj, "group", "label");
        return obj;
      });
      let dump = sortArray.map((obj) => {
        return { label: obj };
      });
      sortedGroupsFormated.unshift({ label: t("Sort by"), subData: dump });
    }

    const layoutDirection = getFromLocal("layoutDirection").toUpperCase();

    return (
      <ContentWrapper>
        {modalNPVRInfo?.showModalNPVR && <ModalNPVRRecord />}
        <Header>
          <Trans>{this.props.route.title}</Trans>
          <div className={style.headerSelector}>
            <div>
              {sortedGroupsFormated?.length > 0 && (
                <Dropdown
                  iconOnClick={<Filter className={classnames(style.filter)} />}
                  title={"EPG"}
                  selfClosing={true}
                  handleClick={this.handleApplyFilter}
                  subDataHandleClick={this.handleApplySort}
                  stayOpenOnSelect={true}
                  data={sortedGroupsFormated}
                />
              )}
            </div>
            <div
              ref={this.findChannelRef}
              className={style.containerSearch}
              onClick={this.toggleClickAccordion.bind(this)}
            >
              <div className={style.titleSearch}>{t("Find a channel")}</div>
              <ArrowHeading
                className={classnames(style.arrow, {
                  [style.arrowOpened]: !menuContextSearchOpen,
                  [style.arrowClosed]: menuContextSearchOpen,
                })}
              />
            </div>
          </div>
        </Header>
        {menuContextSearchOpen === true && (
          <div
            ref={this.menuContextSearchRef}
            style={{
              // top: menuContextSearch.clientX + 50,
              top: viewport.type === ResponsiveType.PHONE ? 120 : 130,
              width:
                viewport.type === ResponsiveType.PHONE
                  ? 290
                  : widthColumn + 155,
              // left:
              //   layoutDirection === "LTR"
              //     ? viewport.type === ResponsiveType.PHONE
              //       ? viewport.width <= 320
              //         ? menuContextSearch.clientY - 87
              //         : menuContextSearch.clientY - 109
              //       : menuContextSearch.clientY - 260
              //     : layoutDirection === "RTL"
              //     ? "50px"
              //     : undefined,
              left: layoutDirection === "LTR" ? "unset" : "50px",
              right: layoutDirection === "RTL" ? "unset" : "50px",
              zIndex: 10,
              display: menuContextSearchOpen ? "block" : "none",
              overflowX: "hidden",
            }}
            className={style.menuContext}
          >
            <div
              style={{
                width:
                  viewport.type === ResponsiveType.PHONE
                    ? "100%"
                    : widthColumn + 145,
              }}
              className={classnames(style.searchContainer, {
                [style.searchContainerFocus]: inputFocus === true,
              })}
            >
              <div
                className={classnames(style.iconSearch, {
                  [style.iconFocus]: inputFocus === true,
                  [style.RTL]: layoutDirection === "RTL",
                })}
              >
                <SearchIcon className={style.icon} />
              </div>
              <input
                className={classnames(style.input, {
                  [style.inputFocus]: inputFocus === true,
                  [style.RTL]: layoutDirection === "RTL",
                })}
                type="text"
                onChange={this.handleInputOnChange.bind(this)}
                value={inputValue}
                placeholder={t("Search")}
              />
            </div>
            <Grid rootClassName={style.gridSearch}>
              {!isEPGLoading &&
                filteredChannels &&
                filteredChannels.foundChannels &&
                filteredChannels.foundChannels.map((chan, index) => {
                  return (
                    <>
                      {(chan.found || term === "") && (
                        <ThumbnailSquare
                          key={index + chan.idChannel}
                          item={chan}
                          fta={chan.fta}
                          size={thumbnailSize}
                          isConnected={isConnected}
                          noPlay={true}
                          isSearch={true}
                          linkToPlayer={false}
                          subscriptionModal={this.subscriptionModal.bind(this)}
                          showFavorite={this.isChannelFavorite(chan.id)}
                          rootClassName={style.gridElement}
                          onClick={() => this.gotoPlayerLive(chan.id)}
                        />
                      )}
                    </>
                  );
                })}
            </Grid>

            {!isEPGLoading &&
              filteredChannels &&
              !filteredChannels.atLeastOneFound &&
              term !== "" && (
                <div className={style.noResults}>
                  <Sad className={style.noResultsIcon} />
                  <p>
                    <Trans>No results on :</Trans>
                    {`  `}
                    {filter ? filter : t("All channels")}
                  </p>
                </div>
              )}
          </div>
        )}
        {isLoading && <Loader centered={true} />}
        {!isLoading && liveEPG && (
          <>
            <InfiniteScroll
              // TODO fix bug of this componenet or try another package (method loadmor get executed before componentdidmount)
              pageStart={0}
              loadMore={this.loadMore.bind(this, false)}
              hasMore={hasMore}
              loader={<Loader centered={false} key={0} />}
            >
              <Grid rootClassName={style.grid}>
                {items.map((channel, index) => {
                  const epg = liveEPG
                    ? liveEPG.find((item) => {
                        return channel.id === item.getChannelId();
                      })
                    : null;
                  return (
                    <Fragment key={index}>
                      <div key={index + channel.name}>
                        <ThumbnailLive
                          key={index + channel.name}
                          channel={channel}
                          epg={epg}
                          fta={channel.fta}
                          isConnected={isConnected}
                          thumbnailSize={thumbnailSize}
                          subscriptionModal={this.subscriptionModal.bind(this)}
                          openMenuContext={this.openMenuContext.bind(this)}
                          isFavorite={this.isChannelFavorite(channel.getId())}
                          history={history}
                          AddToFavorite={this.AddToFavorite.bind(this)}
                          withoutDimensions={true}
                        />
                      </div>
                      {menuContextOpen === true && (
                        <div
                          ref={this.menuContextRef}
                          style={{
                            top: menuContext.clientY + 30,
                            width: widthColumn - 40,
                            left: menuContext.clientX,
                            zIndex: 10,
                          }}
                          className={style.menuContext}
                        >
                          <div className={style.buttonContainer}>
                            {this.isChannelFavorite(
                              menuContext.channel.getId()
                            ) ? (
                              <FavoriteRemove className={style.addFavIcon} />
                            ) : (
                              <FavoriteAdd className={style.addFavIcon} />
                            )}
                            <div
                              className={style.text}
                              onClick={() =>
                                this.AddToFavorite(menuContext.channel.getId())
                              }
                            >
                              {this.isChannelFavorite(
                                menuContext.channel.getId()
                              ) ? (
                                <Trans>Remove from favorite</Trans>
                              ) : (
                                <Trans>Add to favorite</Trans>
                              )}
                            </div>
                          </div>
                          <Link
                            to={consts.routes.playerlive.url.replace(
                              ":channelId",
                              menuContext.channel.getId()
                            )}
                            className={style.buttonContainer}
                          >
                            <Live className={style.watchLiveIcon} />
                            <div
                              to={consts.routes.playerlive.url.replace(
                                ":channelId",
                                menuContext.channel.getId()
                              )}
                              className={style.text}
                            >
                              <Trans>Watch live</Trans>
                            </div>
                          </Link>
                        </div>
                      )}
                    </Fragment>
                  );
                })}
              </Grid>
            </InfiniteScroll>
          </>
        )}
      </ContentWrapper>
    );
  }
}

export default compose(
  connect((state, props) => {
    const EPGDate = state.ui.EPGDate;
    const channelFavorits = state.content.favoritesChannels.data;
    let filter = state.ui.discoverFilter;
    let sort = state.ui.discoverSort;
    const profileToken = state.session.profileToken || null;
    const term = state.ui.searchTerm;
    if (props.isConnected && profileToken) {
      const decoded = jwt_decode(profileToken);
      const channelFilter = state.ui.channelFilter;
      const sortValue = state.ui.channelSort;
      // object that has profile and filter for the given profile
      const profileFilter =
        channelFilter?.filter((item) => item.profile === decoded?.id_profile) ||
        null;
      const profileSort =
        sortValue?.filter((item) => item.profile === decoded?.id_profile) ||
        null;
      filter = (profileFilter?.length > 0 && profileFilter[0]?.value) || null;
      sort = (profileSort?.length > 0 && profileSort[0]?.value) || null;
    }

    const directArrayValueForFavorites = state.ui.directArrayForFavorites;
    return {
      channelFavorits,
      isConnected: state.session.customerAuthToken !== undefined,
      isLoading: state.content.epg.loading,
      viewport: state.ui.viewport,
      modalNPVRInfo: state.ui.modalNPVRInfo,
      liveEPG: TucanoSelectors.getLive(state),
      // channels: TucanoSelectors.getEPG(state),
      profileToken: profileToken,
      EPGDate: EPGDate,
      isEPGLoading: state.content.epg.loading,
      channels: TucanoSelectors.getEPG(
        state,
        EPGDate,
        consts.durationEPGDisplayImage
      ),
      groups: TucanoSelectors.getGroups(state, channelFavorits),
      filter: filter,
      sortValue: sort,
      term: term,
      filteredChannels: TucanoSelectors.getFilteredEpg(
        state,
        EPGDate,
        filter,
        sort,
        term,
        directArrayValueForFavorites,
        channelFavorits,
        consts.durationEPGDisplayImage
      ),
      accountStatus: state.account.user?.data?.status,
      ppvOptions: state.subscription?.ppvOptions?.data || [],
    };
  }),
  withTranslation()
)(PageLive);
