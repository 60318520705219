import { setErrorShow, setSuspensionShow } from "actions/ui";
import classnames from "classnames";
import AutoClassification from "components/AutoClassificationImg";
import FavoriteToggle from "components/FavoriteToggle";
import PadlockLock from "components/Icons/PadlockLock";
import Pipe from "components/Icons/Pipe";
import PlayIcon from "components/Icons/Play";
import PlayLock from "components/Icons/PlayLock";
import PlayUnlock from "components/Icons/PlayUnlock";
import { PlaceholderType } from "components/Image";
import LiveEventTag from "components/LiveEventTag";
import RecordActions from "components/RecordActions";
import ThumbnailTrailer from "components/ThumbnailTrailer";
import consts from "consts/consts";
import { format } from "date-fns";
import { detect } from "detect-browser";
import { getFromLocal, saveInLocal } from "helpers/localStorage";
import { convertDateToTimezone } from "helpers/time";
import { shouldDisplayFlyCatchupPage } from "helpers/url";
import { generateDeviceId, isLiveEventAiringNow } from "helpers/utility";
import isExternal from "is-url-external";
import moment from "moment-timezone";
import PropTypes from "prop-types";
import React, { Fragment, PureComponent } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { TucanoActions, TucanoModels, TucanoSelectors } from "web-api/main";

import routes from "../../web-api/consts/routes";
import style from "./style.module.css";

const TRAILER_DELAY = {
  AUTOPLAY: 0,
  RELAUNCH: 20000,
};

class ThumbnailInfos extends PureComponent {
  state = {
    item: null,
    hasError: false,
    errorMessage: "",
    timeout: 0,
    showTrailer: false,
    cardType: "image",
  };

  async fetch() {
    const { dispatch, model, isConnected } = this.props;
    try {
      if (isConnected) {
        await this.checkDevice();
        // await dispatch(TucanoActions.loginWebPlayer());
      }
      await dispatch(TucanoActions.getTrailerUrl(model.trailerId));
    } catch (_e) {
      // console.log("Errror => ", e);
      return;
    }
  }

  async checkDevice() {
    // Get Device ID From LocalStorage
    let deviceId = getFromLocal(consts.storageKeys.device);
    let device = { id: deviceId, ...detect() };

    if (this.props.deviceAuthToken) {
      return device;
    }

    // Generate One
    deviceId =
      device.id &&
      device.id !== "" &&
      device.id !== null &&
      device.id !== undefined
        ? device.id
        : generateDeviceId();
    saveInLocal(consts.storageKeys.device, deviceId);

    // Add Device
    await this.props.dispatch(TucanoActions.addDevice(deviceId));

    if (!this.props.deviceAuthToken) {
      throw new Error("Cant Add Device");
    }

    //Get New device stored
    device = { id: deviceId, ...detect() };

    // Auth Device
    await this.props.dispatch(TucanoActions.authDevice(device));

    return device;
  }

  async componentDidMount() {
    const { dispatch, isTrailerActive } = this.props;

    if (consts.displayVodData) {
      const { model } = this.props;
      let metadata;
      if (model instanceof TucanoModels.Asset && model.getVodData()) {
        metadata = model.getVodData();
      }
      await this.setState({ item: metadata });
    }
    if (isTrailerActive) {
      await dispatch(TucanoActions.resetAssetTrailerObject());
    }
  }

  componentWillUnmount() {
    const { dispatch, isTrailerActive } = this.props;
    if (isTrailerActive) {
      dispatch(TucanoActions.resetAssetTrailerObject());
    }
  }

  async componentDidUpdate(prevProps) {
    const { timeout, showTrailer } = this.state;
    const { show, isTrailerActive, model } = this.props;

    if (isTrailerActive) {
      if (
        show &&
        prevProps.trailer !== this.props.trailer &&
        !prevProps.trailer
      ) {
        this.setState({ cardType: "video" });
      }
      if (timeout === 0 && !showTrailer && show && model?.trailerId) {
        if (this.props.isPoster) {
          this.props.setShowModal(true);
        }
        this.setState({ showTrailer: true });
        let to = setTimeout(async () => {
          await this.fetch();
        }, TRAILER_DELAY.AUTOPLAY);

        await this.setState({ timeout: to });
      } else if (!show) {
        await this.setState({
          timeout: 0,
          showTrailer: false,
          cardType: "image",
        });
        clearTimeout(timeout);
      }
    }
  }

  getModelTag() {
    const { model, t } = this.props;

    if (model.isCatchupAsset) {
      return t("Catch-up");
    }

    if (model.isAssetTVShow || model.isAssetTVShowEpisode) {
      return t("Series");
    }

    if (model.getType() === "link") {
      return "";
    }

    return t("Movie");
  }

  getModelDuration() {
    const { model, showMoralityLevel, t } = this.props;

    if (model.isAssetTVShowEpisode) {
      return (
        (model.seasonNumber ? t("S") + " " + model.seasonNumber + " - " : "") +
        t("EP") +
        " " +
        model.episodeNumber +
        " | " +
        model.getHumanDuration() +
        (!model.getAutoClassification && model.moralityLevel
          ? " | " + model.moralityLevel + "+"
          : "")
      );
    }

    if (model.isAssetTVShow) {
      return t("Season", { count: model.seasonCount });
    }

    if (this.isChannel()) {
      return t("channel");
    }

    return (
      model.getHumanDuration() +
      (showMoralityLevel && !model.getAutoClassification
        ? model.moralityLevel
          ? " | " + model.moralityLevel + "+"
          : ""
        : "")
    );
  }

  handleChangeFavoriteStatus() {
    const { dispatch, isAssetFavorite, isConnected, model } = this.props;
    let id = parseInt(model.id);
    if (model.isAssetTVShowEpisode === true && !model.isCatchupAsset) {
      id = parseInt(model.parentId);
    }
    if (isConnected) {
      if (isAssetFavorite) {
        dispatch(
          TucanoActions.deleteFavorite(
            id,
            model.isAssetTVShow,
            model.isAssetTVShowEpisode && !model.isCatchupAsset
          )
        ).then((data) => {
          if (data.code === -1) {
            this.setState({
              hasError: true,
              errorMessage: "An error occured. Please try again later",
            });
            this.props.dispatch(
              setErrorShow({
                status: true,
                errorMessage: this.state.errorMessage,
              })
            );
          }
        });
      } else {
        dispatch(
          TucanoActions.addFavorite(
            id,
            model.isAssetTVShow,
            model.isAssetTVShowEpisode && !model.isCatchupAsset,
            getFromLocal("languageId")
          )
        ).then((data) => {
          if (data.code === -1600 || data.code === -1620 || data.code === -1) {
            this.setState({
              hasError: true,
              errorMessage: "An error occured. Please try again later",
            });
            this.props.dispatch(
              setErrorShow({
                status: true,
                errorMessage: this.state.errorMessage,
              })
            );
          }
        });
      }
    } else {
      this.props.history.push("?login=1");
    }
  }
  isChannelFavorite = (id) => {
    const { channelFavorits } = this.props;
    const found =
      channelFavorits &&
      channelFavorits.find((channel) => channel.idChannel === id);
    return found !== undefined;
  };
  async handleChangeFavoriteChannelStatus() {
    const { dispatch, isConnected, model } = this.props;
    let id = parseInt(model.id);
    let channelFavorits = this.props.channelFavorits;
    const isChannelFavorite = this.isChannelFavorite(id);
    if (isConnected) {
      if (isChannelFavorite) {
        const newChannelList = channelFavorits.filter(
          (channel) => channel.idChannel !== id
        );
        await dispatch(TucanoActions.addFavoriteChannel(newChannelList));
      } else {
        let order;
        if (channelFavorits && channelFavorits.length > 0) {
          order = channelFavorits[channelFavorits.length - 1].order + 1;
        } else {
          order = 0;
        }
        if (channelFavorits)
          channelFavorits.push({ idChannel: id, order: order });
        await dispatch(TucanoActions.addFavoriteChannel(channelFavorits));
      }
    } else {
      this.props.history.push("?login=1");
    }
  }
  play(e) {
    e.stopPropagation();
    e.preventDefault();

    const { model } = this.props;
    let url = consts.routes.player.url.replace(":assetId", model.getId());

    if (model instanceof TucanoModels.Channel) {
      url = consts.routes.playerlive.url.replace(":channelId", model.getId());
    }
    if (model instanceof TucanoModels.EPGEventAsset) {
      url = consts.routes.playerlive.url.replace(":channelId", model.channelId);
    }
    if (
      typeof model?.getRecordStatus === "function" &&
      model?.getRecordStatus() === "Recording"
    ) {
      url = consts.routes.playerlive.url.replace(":channelId", model.channelId);
    } else if (
      typeof model?.getRecordStatus === "function" &&
      model.getRecordStatus() === "Recorded"
    ) {
      url =
        consts.routes.player.url.replace(
          ":assetId",
          model?.getRecord()?.getIdRecord()
        ) + "?source_type=npvr";
    }

    if (model.isAssetTVShowEpisode) {
      const data = this.props.hierarchy && this.props.hierarchy[0]?.categories;
      data &&
        data.forEach((categorie) => {
          categorie.categories.forEach((subCat) => {
            if (subCat.name === model.parentTitle && subCat.serie === true) {
              saveInLocal("tvshowId", subCat.idCatalog);
            }
          });
        });
    }

    this.props.history.push(url);
  }

  unlock(e) {
    e.stopPropagation();
    e.preventDefault();

    const { model, accountStatus, dispatch } = this.props;

    if (accountStatus && accountStatus.toLowerCase() === "suspended") {
      dispatch(setSuspensionShow());
    } else if (this.props.subscriptionModal) {
      this.props.subscriptionModal(model);
    }
  }

  isPlayable() {
    const { model } = this.props;

    if (this.props.noPlay) return false;
    if (model.isCategory) return false;
    if (model.isAssetTVShow && !model.isAssetTVShowEpisode) return false;
    if (model.getType() === "link") return false;
    if (
      model.isLiveEvent &&
      !isLiveEventAiringNow(model.getStartTime(), model.getEndTime())
    )
      return false;
    return true;
  }

  isLock() {
    const { model, isLocked, accountStatus } = this.props;
    if (!consts.padlocks) return false;
    if (model.isCategory && !model.isAssetTVShowEpisode) return false;
    if (model.isAssetTVShow && !model.isAssetTVShowEpisode) return false;
    if (model.getType() === "link") return false;
    if (accountStatus && accountStatus.toLowerCase() === "suspended")
      return true; //Check if the account is suspended to show  locks
    if (!this.props.isConnected) return false;
    if (
      (model.validityEndDate &&
        model.validityEndDate * 1000 < new Date().getTime()) ||
      isLocked
    )
      return true;
    return !model.userHasAccess;
  }

  isChannel() {
    const { model } = this.props;
    if (model instanceof TucanoModels.Channel) {
      return model.constructor === TucanoModels.Channel;
    }
  }

  onTrailerEnds() {
    clearTimeout(this.state.timeout);

    if (this.props.isPoster) {
      this.props.setShowModal(false);
    }

    let to = setTimeout(() => {
      if (this.props.isPoster) {
        this.props.setShowModal(true);
      }
      this.setState({ showTrailer: true });
    }, TRAILER_DELAY.RELAUNCH);

    this.setState({
      timeout: to,
      showTrailer: false,
    });
  }

  render() {
    const {
      model,
      onlyPlay,
      show,
      displayFavoriteToggle,
      isAssetFavorite,
      t,
      onlyFavIcon,
      ratioCircle,
      showOnlyLogo,
      trailer,
      isTrailerActive,
      isPoster,
      isConnected,
      showSmallThumbnail,
      i18n,
      match,
      statusNPVRRecord,
      showIconNPVRRecord,
      channel,
    } = this.props;
    const direction = getFromLocal("layoutDirection");
    const { showTrailer, cardType } = this.state;
    let type;
    if (model.getType() === "link") {
      type = "link";
    }
    if (
      model.getType() === "EPGBroadcast" ||
      model instanceof TucanoModels.EPG
    ) {
      type = "epgEvent";
    }
    let displayPlayIcon = true;
    if (
      (model instanceof TucanoModels.Channel &&
        model.type === consts.imageTypes.seriePoster) ||
      this.isLock() ||
      (type === "epgEvent" &&
        !model.isLive() &&
        statusNPVRRecord !== "Recorded")
    ) {
      displayPlayIcon = false;
    }

    const borderRadiusStyle =
      (consts.thumbnailBorderRadius &&
        consts.thumbnailStyle.length > 0 &&
        style[
          consts.thumbnailStyle[0][consts.thumbnailBorderRadius.toLowerCase()]
        ]) ||
      "";

    const showTopRightFavoriteToggle =
      (!consts.trailerOnFocus || !isPoster || (isPoster && showTrailer)) &&
      isConnected &&
      (!model.isCategory || model.isSerie) &&
      !this.isChannel() &&
      type !== "link" &&
      displayFavoriteToggle &&
      type !== "epgEvent" &&
      !showOnlyLogo &&
      model.getDesignMode() !== "circle";
    const showLiveEventTag =
      model.getDesignMode() !== "circle" &&
      model.getDesignMode() !== "square" &&
      model.isLiveEvent;
    return (
      <div className={classnames(style.container)}>
        {showLiveEventTag && (
          <LiveEventTag
            withToolTip
            t={t}
            startDate={model.getStartTime()}
            endDate={model.getEndTime()}
          />
        )}
        {/* BACKGROUND OVERLAY */}
        <div
          className={classnames(style.background, borderRadiusStyle, {
            [style.background_show]: show,
          })}
        />
        {/* CONTENT */}
        <div className={classnames(style.content)}>
          {/* Trailer image */}
          {showTrailer && isTrailerActive && (
            <div
              className={classnames(style.trailerImage, {
                [style.trailerImage_cover]: !isPoster,
                [style.trailerImage_toggle]: cardType === "video",
                [style.trailerImage_poster]: isPoster,
              })}
              style={{
                backgroundImage: showSmallThumbnail
                  ? `url(${model.getPosterImage(consts.image_hostname)})`
                  : "",
                backgroundPosition: showSmallThumbnail ? "center" : "",
              }}
            ></div>
          )}
          {showTrailer &&
            trailer &&
            cardType === "video" &&
            trailer.url &&
            isTrailerActive && (
              <ThumbnailTrailer
                trailerUrl={trailer.url}
                onTrailerEnds={this.onTrailerEnds.bind(this)}
                isPoster={isPoster}
              />
            )}

          {/* TOP */}
          {(!model.isCategory || model.isAssetTVShow) &&
            !ratioCircle &&
            this.isChannel() && (
              <Fragment>
                <div
                  className={classnames(style.content_top, {
                    [style.content_top_show]: show || showOnlyLogo,
                  })}
                >
                  {/* TOP LEFT */}
                  <div className={style.channelNumber}>
                    {model.getLocalizeNumber()}
                  </div>
                </div>
              </Fragment>
            )}

          {!onlyPlay && (
            <>
              {shouldDisplayFlyCatchupPage(match.path) &&
                !ratioCircle &&
                !this.isChannel() &&
                onlyFavIcon === false &&
                model.isCatchupAsset &&
                typeof model.getProviderId === "function" &&
                !!model.getProviderId() && (
                  <img
                    className={style.channel}
                    height={40}
                    width={40}
                    src={`${consts.image_hostname}${routes.imgdata}?type=${
                      consts.imageTypes.tvChannel
                    }&objectId=${model.getProviderId()}&format_w=80&format_h=80&languageId=${
                      consts.languageId
                    }`}
                    placeholder={PlaceholderType.TRANSPARENT}
                  />
                )}
              {/* TOP RIGHT */}
              {showTopRightFavoriteToggle &&
              !isExternal(!model.getUrl ? "" : model.getUrl()) &&
              !statusNPVRRecord ? (
                <>
                  <FavoriteToggle
                    onFavoriteClick={this.handleChangeFavoriteStatus.bind(this)}
                    isAssetFavorite={isAssetFavorite}
                    rootClassName={
                      showTrailer && isPoster
                        ? classnames(style.favoriteIcon_trailer, {
                            [style.favoriteIconCircle]: ratioCircle,
                            [style.favoriteIcon_trailer_poster]:
                              isPoster && showTrailer,
                          })
                        : classnames(
                            direction.toLocaleLowerCase() === "rtl"
                              ? style.favoriteIconRTL
                              : style.favoriteIcon,
                            {
                              [style.favoriteIconCircle]: ratioCircle,
                            }
                          )
                    }
                  />
                </>
              ) : (
                showIconNPVRRecord && (
                  <div className={style.npvrIcon}>
                    <RecordActions
                      epg={model}
                      channel={channel}
                      type={statusNPVRRecord}
                      withTitle={false}
                    />
                  </div>
                )
              )}
              {!ratioCircle &&
                this.isChannel() &&
                this.props.showFavorite &&
                !isExternal(!model.getUrl ? "" : model.getUrl()) && (
                  <FavoriteToggle
                    onFavoriteClick={this.handleChangeFavoriteChannelStatus.bind(
                      this
                    )}
                    isAssetFavorite={this.isChannelFavorite(
                      parseInt(model.getId())
                    )}
                    rootClassName={classnames(style.favoriteIcon, {
                      [style.favoriteIconCircle]: ratioCircle,
                    })}
                  />
                )}
            </>
          )}

          {/*FAVORITE ONLY*/}
          {onlyFavIcon === true &&
            !isExternal(!model.getUrl ? "" : model.getUrl()) && (
              <FavoriteToggle
                onFavoriteClick={this.handleChangeFavoriteStatus.bind(this)}
                isAssetFavorite={isAssetFavorite}
                rootClassName={classnames(style.favoriteIconSingle, {
                  [style.favoriteIconSingle_show]: show,
                })}
              />
            )}

          {/* PLAY */}
          {this.isPlayable() &&
            displayPlayIcon &&
            (!consts.trailerOnFocus ||
              !isPoster ||
              (isPoster && showTrailer)) && (
              <div
                className={classnames({
                  [style.fullWidth]: model?.isAssetTVShowEpisode,
                })}
                // style={{ width: "100%", height: "100%" }}
                onClick={this.play.bind(this)}
              >
                <PlayIcon
                  className={
                    !showTrailer
                      ? classnames(style.playIcon, {
                          [style.playIcon_show]: show,
                        })
                      : classnames(style.playIcon_trailer, {
                          [style.playIcon_show]: show,
                          [style.playIcon_show_poster]: isPoster,
                        })
                  }
                />
              </div>
            )}

          {/* LOCK */}
          {this.isLock() && (
            <div
              className={classnames(style.lockContainer, {
                [style.fullWidth]: model?.isAssetTVShowEpisode,
              })}
              onClick={this.unlock.bind(this)}
            >
              <PlayLock
                className={
                  !showTrailer
                    ? classnames(style.playIcon, style.playLockIcon, {
                        [style.playIcon_show]: show,
                      })
                    : classnames(style.playIcon_trailer, style.playLockIcon, {
                        [style.playIcon_show]: show,
                      })
                }
              />
              <PlayUnlock
                className={
                  !showTrailer
                    ? classnames(style.playIcon, style.playUnlockIcon, {
                        [style.playIcon_show]: show,
                      })
                    : classnames(style.playIcon_show, style.playUnlockIcon, {
                        [style.playIcon_show]: show,
                      })
                }
              />
              <div
                className={classnames(
                  style.padlockContainer,
                  style[
                    consts.thumbnailBorderRadius.toLowerCase() +
                      "PadlockContainerRadius"
                  ],
                  {
                    [style.hidden]: show,
                  }
                )}
              >
                <div className={classnames(style.padlockIconContainer, {})}>
                  <PadlockLock className={style.padlockLockIcon} />
                </div>
              </div>
            </div>
          )}

          {/* BOTTOM */}
          {!onlyPlay && type !== "link" && !ratioCircle && (
            <div
              className={classnames(
                `${
                  !showTrailer
                    ? style.content_bottom
                    : style.content_bottom_trailer
                }`,
                {
                  [style.content_bottom_show]: show,
                  [style.content_bottom_trailer_poster]:
                    isPoster && showTrailer,
                  [style.content_bottom_poster]: isPoster && showTrailer,
                  [style.content_bottom_padd]: true,
                }
              )}
            >
              {onlyFavIcon === false &&
                !model.isSerie &&
                !model.isCatchupAsset &&
                !model.isCategory &&
                type !== "epgEvent" && (
                  <Fragment>
                    <div className={style.bottom_text}>
                      {this.isChannel()
                        ? model.getName()
                        : /*: model.isSerie
                        ? model.getTitle()*/
                          model.parentTitle || model.getTitle()}
                    </div>
                    {!this.isChannel() && (
                      <div className={style.bottom_subtext}>
                        {/* {t(this.getModelTag())} */}
                        {this.getModelDuration() && (
                          <Fragment>
                            {/* <Pipe className={style.pipeIcon} /> */}
                            {t(this.getModelDuration())}
                            {model?.getAutoClassification ? (
                              <>
                                {" | "}
                                <AutoClassification
                                  morality={model.getAutoClassification}
                                />
                              </>
                            ) : (
                              ""
                            )}
                          </Fragment>
                        )}
                      </div>
                    )}
                  </Fragment>
                )}
              {!this.isChannel() &&
                onlyFavIcon === false &&
                model.isCatchupAsset && (
                  <Fragment>
                    <div className={style.bottom_text}>
                      {
                        /*model.isSerie
                        ? model.getTitle()
                        : */ model.parentTitle || model.getTitle()
                      }
                    </div>
                    {!!model.getValidityStartDate() && (
                      <div className={style.bottom_subtext}>
                        {`${t(
                          moment
                            .utc(model.getValidityStartDate(), "DD/MM/YYYY")
                            .locale(
                              i18n.language == "ar" ? "ar-MA" : i18n.language
                            )
                            .tz(
                              Intl.DateTimeFormat().resolvedOptions()
                                .timeZone || moment.tz.guess()
                            )
                            .format(consts.catchupDateFormat)
                        )}
                      ${convertDateToTimezone(
                        model.getStartTime(),
                        i18n.language
                      )}`}
                      </div>
                    )}
                  </Fragment>
                )}
              {!this.isChannel() &&
                onlyFavIcon === false &&
                model.isSerie &&
                !model.isCatchupAsset && (
                  <Fragment>
                    <div className={style.bottom_text}>
                      {
                        /*model.isSerie
                        ? model.getTitle()
                        : */ model.parentTitle || model.getTitle()
                      }
                    </div>
                    <div className={style.bottom_subtext}>
                      {t(this.getModelTag())}
                      {this.getModelDuration() && (
                        <Fragment>
                          <Pipe className={style.pipeIcon} />
                          {t(this.getModelDuration())}
                        </Fragment>
                      )}
                    </div>
                  </Fragment>
                )}
              {!this.isChannel() &&
                onlyFavIcon === false &&
                !model.isSerie &&
                !model.isCatchupAsset &&
                model.isCategory &&
                model.getTitle() && (
                  <Fragment>
                    <div className={style.bottom_text}>
                      {
                        /*model.isSerie
                        ? model.getTitle()
                        : */ model.parentTitle || model.getTitle()
                      }
                    </div>
                  </Fragment>
                )}
              {!this.isChannel() && type === "epgEvent" && (
                <Fragment>
                  <div className={style.bottom_text}>
                    {
                      /*model.isSerie
                      ? model.getTitle()
                      : */ model.parentTitle || model.getTitle()
                    }
                  </div>
                  {model.isLive() ? (
                    <div className={style.bottom_subtext}>
                      {format(model.getStartTime(), "HH:mm")} -{" "}
                      {format(model.getEndTime(), "HH:mm")}
                    </div>
                  ) : (
                    <div className={style.bottom_subtext}>
                      {model.getBroadcastTime()}
                    </div>
                  )}
                </Fragment>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

ThumbnailInfos.defaultProps = {
  model: {},
  show: true,
  onlyPlay: false,
  onlyFavIcon: false,
  noPlay: false,
  displayFavoriteToggle: true,
  showOnlyLogo: false,
  showSmallThumbnail: true,
  isLocked: false,
  showIconNPVRRecord: false,
};

ThumbnailInfos.propTypes = {
  model: PropTypes.object,
  show: PropTypes.bool,
  onlyPlay: PropTypes.bool,
  onlyFavIcon: PropTypes.bool,
  noPlay: PropTypes.bool,
  displayFavoriteToggle: PropTypes.bool,
  showSmallThumbnail: PropTypes.bool,
  showOnlyLogo: PropTypes.bool,
};

export default withRouter(
  compose(
    connect((state, props) => {
      const channelFavorits = state.content.favoritesChannels.data;
      let isAssetFavorite = false;
      const favorites = TucanoSelectors.getFavorites(state);
      if (favorites !== undefined) {
        isAssetFavorite = favorites.some((favorite) => {
          if (
            props.model.isAssetTVShowEpisode === true &&
            !props.model.isCatchupAsset
          ) {
            return favorite.id === parseInt(props.model.parentId);
          } else {
            return favorite.id === props.model.id;
          }
        });
      }
      return {
        channelFavorits,
        isAssetFavorite,
        isConnected: state.session.customerAuthToken !== undefined,
        trailer: TucanoSelectors.getAssetTrailer(state),
        deviceAuthToken: state.session.deviceAuthToken !== undefined,
        hierarchy: state.reference.hierarchy.data,
        accountStatus: state.account.user?.data?.status,
      };
    }),
    withTranslation()
  )(ThumbnailInfos)
);
